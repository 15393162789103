import axios from 'axios';
import React, { useState } from 'react';

const UpdateProfile = ({profile, setDisplayUpdateComponent, setUpdateProfileInfo}) => {
    const [formData, setFormData] = useState({
        businessEmail: profile.businessEmail,
        businessAddress: {
            streetAddress: profile.businessAddress?.streetAddress || '',
            aptSuite: profile.businessAddress?.aptSuite || '',
            city: profile.businessAddress?.city || '',
            state: profile.businessAddress?.state || '',
            zipCode: profile.businessAddress?.zipCode || '',
        },
        businessPhone: profile.businessPhone,
        businessImage: profile.iconPhoto, 
        businessType: profile.businessType, 
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('businessAddress')) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                businessAddress: {
                    ...prevFormData.businessAddress,
                    [name.split('.')[1]]: value,
                },
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        };
    };

    const handleFileChange = (e) => {
        setFormData({ 
            ...formData,
            businessImage: e.target.files,
        });
    };

    const handleClose = () => {
        setDisplayUpdateComponent(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('authToken');
        const formDataToSend = new FormData();
    
        if (formData.businessImage && formData.businessImage.length > 0) {
            formDataToSend.append("businessImage", formData.businessImage[0]);
        }
    
        formDataToSend.append("businessStreetAddress", formData.businessAddress.streetAddress);
        formDataToSend.append("businessAptSuite", formData.businessAddress.aptSuite);
        formDataToSend.append("businessCity", formData.businessAddress.city);
        formDataToSend.append("businessState", formData.businessAddress.state);
        formDataToSend.append("businessZipCode", formData.businessAddress.zipCode);
        formDataToSend.append("businessPhone", formData.businessPhone);
        formDataToSend.append("businessType", formData.businessType);
    
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_ENDPOINT}/profile/update/profile-info`,
                formDataToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setUpdateProfileInfo(response.data);
            setDisplayUpdateComponent(false);
        } catch (error) {
            console.error(
                'There was an error adding your profile picture',
                error.response?.data || error.message
            );
        };
    };
    
    return (
      <div className="update-form">
        <div>
          <span onClick={handleClose} className="update-form-exit">
            X
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="businessImage">Profile Picture</label>
          <input
            type="file"
            name="businessImage"
            id="businessImage"
            onChange={handleFileChange}
            multiple
          />
          <div className="setting-input-container">
            <label htmlFor="businessEmail">Business Email</label>
            <input
              disabled
              type="text"
              name="businessEmail"
              id="businessEmail"
              value={formData.businessEmail}
              onChange={handleChange}
              placeholder="contact@shopbstreet.com"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessPhone">Business Number</label>
            <input
              disabled
              type="text"
              name="businessPhone"
              id="businessPhone"
              value={formData.businessPhone}
              onChange={handleChange}
              placeholder="999-999-9999"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessStreetAddress">
              Business Address (Pick up address)
            </label>
            <input
              type="text"
              name="businessAddress.streetAddress"
              id="businessStreetAddress"
              value={formData.businessAddress.streetAddress}
              onChange={handleChange}
              placeholder="123-11 B-Street Blvd"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessAptSuite">Business Suite or Apt</label>
            <input
              type="text"
              name="businessAddress.aptSuite"
              id="businessAptSuite"
              value={formData.businessAddress.aptSuite}
              onChange={handleChange}
              placeholder="3D"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessCity">Business City</label>
            <input
              type="text"
              name="businessAddress.city"
              id="businessCity"
              value={formData.businessAddress.city}
              onChange={handleChange}
              placeholder="New York City"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessState">Business State</label>
            <input
              type="text"
              name="businessAddress.state"
              id="businessState"
              value={formData.businessAddress.state}
              onChange={handleChange}
              placeholder="New York"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessZipCode">Business Zip Code</label>
            <input
              type="text"
              name="businessAddress.zipCode"
              id="businessZipCode"
              value={formData.businessAddress.zipCode}
              onChange={handleChange}
              placeholder="11212"
            />
          </div>
          <div className="setting-input-container">
            <label htmlFor="businessType">Business Type</label>
            <select
              name="businessType"
              defaultValue={formData.businessType}
              onChange={handleChange}
            >
              <option value="Clothing and Apparel">Clothing and Apperal</option>
              <option value="Beauty and Skincare">Beauty and Skincare</option>
              <option value="Handmade Goods">Handmade Goods</option>
              <option value="Electronics and Gadgets">
                Electronics and Gadgets
              </option>
              <option value="Health and Wellness">Health and Wellness</option>
              <option value="Home and Living">Home and Living</option>
              <option value="Books and Educational">
                Books and Educational
              </option>
              <option value="Pets">Pets</option>
              <option value="Jewelry and Accessories">
                Jewelry and Accessories
              </option>
            </select>
          </div>
          <button type="submit">Update Profile</button>
        </form>
      </div>
    );
}

export default UpdateProfile;
