import React, { useEffect } from 'react'
import Navigation from '../navigation/Navigation';
import LandingPage from './partials/LandingPage';

const CreateAccount = () => {
    useEffect(()=> {
        localStorage.clear();
    },[]);
    
  return (
    <div className='login'>
        <Navigation />
        <div className="login-container">
            <LandingPage />
        </div>
    </div>
  )
}

export default CreateAccount