import React, { useEffect, useState } from 'react';
import './settings.css';
import axios from 'axios';
import VerifiedNavigation from '../navigation/VerifiedNavigation';
import UpdateProfile from './UpdateProfile';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const [updateProfileInfo, setUpdateProfileInfo] = useState('');
    const [profile, setProfile] = useState([]);
    const [displayUpdateProfile, setDisplayUpdateComponent] = useState(false);
    const navigate = useNavigate();

    const handleRedirect = () => {
      navigate("/manager/support");
    };

    const handleClick = () => {
        setDisplayUpdateComponent(true);
    };

    const fetchProfile = async() => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/profile`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            setProfile(response.data.user)
        } catch (error) {
            console.error("There was an error fetching your profile information", error)
        }
    };

    const handleStripeDashboard = async () => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/stripe/account-dashboard`, 
                { accountId: profile.stripeAccountId }, 
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            ); 
            
            if (response.data && response.data.url) {
                window.open(response.data.url, '_blank'); 
            }
        } catch (error) {
            console.error("There was an error fetching the Stripe account", error);
            alert("Failed to fetch Stripe dashboard. Please try again.");
        }
    };
    

    useEffect(() => {
        if(!localStorage.getItem('authToken')){
            navigate('/')
        }
        fetchProfile()
    },[updateProfileInfo]);
    return (
      <div className="settings">
        <VerifiedNavigation />
        <div className="settings-container">
          {profile ? (
            <img
              className="update-profile-info"
              src={`${profile.iconPhoto}`}
              alt="profile picture"
            />
          ) : (
            "Upload a image of your storefront"
          )}
          <div className="profile-container">
            <div className="profile-header">
              <h1>Business Profile</h1>
            </div>
            <div className="profile-details">
              <div className="profile-detail">
                <label>Business Name: </label>
                <span>{profile.businessName}</span>
              </div>
              <div className="profile-detail">
                <label>Business Email: </label>
                <span>{profile.businessEmail}</span>
              </div>
              <div className="profile-detail">
                <label>Business Number: </label>
                <span>{profile.businessPhone}</span>
              </div>
              <div className="profile-detail">
                <label>Business Address: </label>
                {profile.businessAddress &&
                profile.businessAddress.streetAddress ? (
                  <span>{profile.businessAddress.streetAddress}</span>
                ) : (
                  <span>Please add your pickup location!</span>
                )}
              </div>
              <div className="profile-detail">
                <label>Business Type: </label>
                <span>{profile.businessType}</span>
              </div>
              {/* <p onClick={handleRedirect}>
                To update your profile information please <a href="" target='_blank' rel="noopener noreferrer">CLICK HERE</a>
              </p> */}
              <button onClick={handleClick}>Update your profile</button>
            </div>
          </div>
          {
                    displayUpdateProfile ? 
                    <UpdateProfile profile={profile} setDisplayUpdateComponent={setDisplayUpdateComponent} setUpdateProfileInfo={setUpdateProfileInfo}/> : null
                }
          <button className="stripe-redirect" onClick={handleStripeDashboard}>
            Stripe Dashboard
          </button>
        </div>
      </div>
    );
};

export default Settings