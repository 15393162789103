import React, { useState } from "react";
import Navigation from "../../navigation/Navigation";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const StoreOperations = () => {
  const [storeDetails, setStoreDetails] = useState({});
  const [serverResponse, setServerResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setStoreDetails((prevState) => ({ ...prevState, [name]: file }));
  };

  const generateTimeOptions = () => {
    const options = [
      <option key="default" value="">
        -Select a time-
      </option>,
    ];
    for (let hour = 0; hour < 24; hour++) {
      const hour24 = hour.toString().padStart(2, "0") + ":00";
      const hour12 = hour % 12 === 0 ? 12 : hour % 12;
      const ampm = hour < 12 ? "AM" : "PM";
      const label = `${hour12}:00 ${ampm}`;
      options.push(
        <option key={hour24} value={hour24}>
          {label}
        </option>
      );
    }
    return options;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    const operationHours = {
      monday: {
        open: storeDetails.mondayOpen,
        close: storeDetails.mondayClose,
      },
      tuesday: {
        open: storeDetails.tuesdayOpen,
        close: storeDetails.tuesdayClose,
      },
      wednesday: {
        open: storeDetails.wednesdayOpen,
        close: storeDetails.wednesdayClose,
      },
      thursday: {
        open: storeDetails.thursdayOpen,
        close: storeDetails.thursdayClose,
      },
      friday: {
        open: storeDetails.fridayOpen,
        close: storeDetails.fridayClose,
      },
      saturday: {
        open: storeDetails.saturdayOpen,
        close: storeDetails.saturdayClose,
      },
      sunday: {
        open: storeDetails.sundayOpen,
        close: storeDetails.sundayClose,
      },
    };

    const formDataToSend = new FormData();
    formDataToSend.append("operationHours", JSON.stringify(operationHours));
    formDataToSend.append("storeNumber", storeDetails.storeNumber);
    formDataToSend.append(
      "pickupInstructions",
      storeDetails.pickupInstructions
    );
    formDataToSend.append("preparationTime", storeDetails.preparationTime);
    formDataToSend.append("website", storeDetails.website);
    formDataToSend.append("photoId", storeDetails.photoId);

    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/profile/store-operations`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate("/register/account-setup");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setServerResponse("Error submitting store details:", error);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Navigation />
      <div className="store-operations">
        <h1>Complete Your Store Setup</h1>
        <form onSubmit={handleSubmit} className="setup-form">
          <section className="operating-hours">
            <h2>Operating Hours (Monday - Sunday)</h2>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((day) => (
              <div className="day" key={day}>
                <label htmlFor={day}>
                  {day.charAt(0).toUpperCase() + day.slice(1)}:
                </label>
                <select
                  required
                  onChange={handleInputChange}
                  name={`${day}Open`}
                  id={`${day}Open`}
                >
                  {generateTimeOptions()}
                </select>
                <select
                  required
                  onChange={handleInputChange}
                  name={`${day}Close`}
                  id={`${day}Close`}
                >
                  {generateTimeOptions()}
                </select>
              </div>
            ))}
          </section>

          <section className="form-section">
            <h2>Pickup & Delivery Instructions</h2>
            <label htmlFor="pickupInstructions">Pickup Instructions</label>
            <input
              required
              type="text"
              name="pickupInstructions"
              placeholder="Come to the front door and receive package"
              value={storeDetails.pickupInstructions || ""}
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="storeNumber">Customer Service Number</label>
            <input
              required
              type="text"
              name="storeNumber"
              placeholder="Number for customers to reach your store"
              value={storeDetails.storeNumber || ""}
              onChange={handleInputChange}
              className="form-input"
            />
            <label htmlFor="website">Website</label>
            <input
              required
              type="text"
              name="website"
              placeholder="www.example.com or social media account (Used for verification purposes)"
              value={storeDetails.website || ""}
              onChange={handleInputChange}
              className="form-input"
            />
          </section>

          <section className="form-section">
            <h2>Photo ID Verification (Must match business owner name)</h2>
            <input
              required
              type="file"
              name="photoId"
              onChange={handleFileChange}
              className="form-input-file"
            />
          </section>
          <button type="submit" className="submit-button">
            {loading ? "Loading" : "Submit Setup Details"}
          </button>
        </form>
        {serverResponse ? <p>{serverResponse}</p> : null}
      </div>
    </React.Fragment>
  );
};

export default StoreOperations;
