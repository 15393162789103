import React, { useEffect, useState } from 'react'
import VerifiedNavigation from '../navigation/VerifiedNavigation'
import axios from 'axios';
import './support.css'
import { useNavigate } from 'react-router-dom';

const Support = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Simple validation check
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email address is invalid';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const token = localStorage.getItem('authToken');

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/support`, formData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      setSuccessMessage('Your message has been sent successfully. We will contact you shortly.');
      setFormData({ name: '', email: '', message: '' });
      setErrors({});
    } catch (error) {
      console.error('Error sending message:', error);
      setSuccessMessage('There was an error sending your message. Please try again.');
    }
  };

  useEffect(()=>{
    if(!localStorage.getItem('authToken')){ 
      navigate('/')
    }
  },[]);
  return (
    <div className='support'>
      <VerifiedNavigation />
      <div className="support-container">
        <div className="support-page">
          <div className="support-header">
            <h1>Contact Customer Support</h1>
            <p>If you need assistance, we're here to help! You can contact us through the form below or reach us directly via phone or email.</p>
          </div>

          <div className="support-form-container">
            <h2>Send us a message</h2>

            {successMessage && <p className="success-message">{successMessage}</p>}

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Your Name *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <p className="error-message">{errors.name}</p>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Your Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>

              <div className="form-group">
                <label htmlFor="message">Your Message *</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                {errors.message && <p className="error-message">{errors.message}</p>}
              </div>

              <button type="submit" className="submit-btn">Send Message</button>
            </form>

            <div className="support-options">
              <h3>Other ways to reach us:</h3>
              <p>Email: <a href="mailto:contact@shopbstreet.com">contact@shopbstreet.com</a></p>
              <p>Phone: <a href="tel:+16463522512">+1 (646) 352-2512</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support