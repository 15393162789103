import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import CreateAccount from "./components/createAccount/CreateAccount";
import ProgressStepDocuments from "./components/createAccount/partials/ProgressStepDocuments";
import Support from "./components/support/Support";
import Orders from "./components/orders/Orders";
import Settings from "./components/settings/Settings";
import OrderModal from "./components/orders/OrderModal";
import { useEffect, useState } from "react";
import axios from "axios";
import Inventory from "./components/inventory/Inventory";
import AddProducts from "./components/inventory/AddProducts";
import ProductModal from "./components/inventory/ProductModal";
import StoreSetup from "./components/createAccount/partials/StoreSetup";
import StoreOperations from "./components/createAccount/partials/StoreOperation";
import StripeSetup from "./components/createAccount/partials/StripeSetup";
import io from "socket.io-client";
const socket = io.connect(process.env.REACT_APP_SERVER_ENDPOINT);

function App() {
  const [orders, setOrders] = useState([]);
  const [authenticated, setAuthenticated] = useState();
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      if (!authenticated) {
        console.error("Error: Please try again");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/orders`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticated}`,
          },
        }
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchBusinessSocket = async () => {
    if (!authenticated) {
      navigate("/");
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticated}`,
          },
        }
      );
      const data = response.data.user._id;
      socket.emit("joinRoom", { data });
    } catch (error) {
      console.error("Error fetching business socket", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setAuthenticated(token)
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchOrders();
      fetchBusinessSocket();
    }
  }, [authenticated]);

  useEffect(() => {
    try {
      socket.on("order-update", async (newOrder) => {
        setOrders((prevOrders) => [...prevOrders, newOrder.orderDetails]);

        if (!authenticated) {
          console.error("Error: Please try again");
          return;
        }
      });
    } catch (error) {
      console.error("There was an error showing the new orders", error);
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<CreateAccount />} />
        <Route path="/register/account-setup" element={<StoreSetup />} />
        <Route
          path="/register/account-setup/package"
          element={<StripeSetup />}
        />
        <Route
          path="/register/account-setup/store"
          element={<StoreOperations />}
        />
        <Route
          path="/register/account-setup/await-review"
          element={<ProgressStepDocuments />}
        />
        <Route
          path="/manager/inventory/:productModal"
          element={<ProductModal />}
        />
        <Route
          path="/manager/orders"
          element={<Orders setOrders={setOrders} orders={orders} />}
        />
        <Route path="/manager/orders/:orderNumber" element={<OrderModal />} />
        <Route path="/manager/inventory" element={<Inventory />} />
        <Route
          path="/manager/inventory/add-new-product"
          element={<AddProducts />}
        />
        <Route path="/manager/support" element={<Support />} />
        {/* <Route path='/manager/sales' element={<Sales />} /> */}
        <Route path="/manager/settings" element={<Settings />} />
      </Routes>
    </div>
  );
}

export default App;
