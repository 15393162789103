import React, { useEffect, useState } from "react";
import VerifiedNavigation from "../navigation/VerifiedNavigation";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./orders.css";
import { useMediaQuery } from "react-responsive";
import io from "socket.io-client";
const socket = io.connect(process.env.RENDER_REDIS_URL);

const OrderModal = () => {
  const [order, setOrder] = useState({});
  const [orderStatus, setOrderStatus] = useState("pending");
  const [doordashData, setDoordashData] = useState({});
  const [orderRejected, setOrderRejected] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [orderCanceledReason, setOrderCanceledReason] = useState("");
  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const fetchOrders = async (token) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/orders`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    mapOrders(response.data);
  };

  const mapOrders = (data) => {
    const orderMatched = data.filter(
      (data) => data.orderNumber === orderNumber
    );
    setOrderStatus(orderMatched[0].orderStatus);
    setOrder(orderMatched);
  };

  const handleAcceptOrder = async () => {
    const token = localStorage.getItem("authToken");
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/orders/update/order-status`,
      { order },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setOrderStatus(response.data.orderStatus);
    socket.emit("orderConfirmed", { order, orderNumber, orderStatus });
  };

  const handleReadyForPickUp = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/doordash/create-delivery`,
        { order },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDoordashData(response.data);
      setOrderStatus(response.data.orderStatus);
      doordashDeliveryConfirmation();
    } catch (error) {
      console.error("There was an error contacting your driver", error);
    }
  };

  const handleRejectOrder = () => {
    setOrderRejected(true);
  };

  const handleRejectSubmit = () => {
    if (rejectReason) {
      socket.emit("orderRejected", {
        orderNumber: orderNumber,
        reason: rejectReason,
      });
    }
  };

  const doordashDeliveryConfirmation = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/doordash/send-delivery-confirmation`,
        { order },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("There was an error sending delivery confirmation", error);
    }
  };

  //   useEffect(() => {
  //     if (doordashData && doordashData?.data) {
  //       socket.emit("deliveryInProgress", {
  //         trackUrl: doordashData.data?.tracking_url,
  //       });
  //     } else {
  //       console.log("Tracking URL not available");
  //     }
  //   }, [doordashData]);

  useEffect(() => {
    socket.on("deliveryUpdateWebHook", async (data) => {
      try {
        const { event_name, cancellation_reason } = data;
        setOrderStatus(event_name);
        setOrderCanceledReason(cancellation_reason);
      } catch (error) {
        console.error(
          "There was an error displaying the webhook response",
          error
        );
      }
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/");
    } else {
      fetchOrders(token);
    }
  }, []);

  return (
    <div className="order-modal">
      <VerifiedNavigation />
      <div className="order-modal-container">
        {order && order.length > 0
          ? order.map((o, index) => (
              <div key={index} className="order">
                <div className="order-name">
                  <div>
                    <h1>{o.customer.name}</h1>
                    <p>Order Number: {o.orderNumber}</p>
                    <p>Contact Number: {o.customer.phoneNumber}</p>
                  </div>
                  <div className="order-date">{o.orderAt}</div>
                </div>
                <div className="order-details">
                  <div className="order-details-header">
                    {isMobile ? null : <h2>#</h2>}
                    <h2>Name</h2>
                    <h2>Qty</h2>
                    <h2>Price</h2>
                    <h2>Item Code</h2>
                  </div>
                  {o.items.map((item, index) => (
                    <div className="order-item" key={index}>
                      {isMobile ? null : <p>{index}</p>}
                      <p>{item.name}</p>
                      <p>{item.quantity}</p>
                      <p>${item.price}</p>
                      <p>#{item._id.slice(10)}</p>
                      <p>{item.specialInstructions}</p>
                    </div>
                  ))}
                </div>
                {orderStatus === "delivery in progress" ||
                orderStatus === "delivered" ? (
                  <div className="doordash-tracking">
                    <h2>
                      To view this order live click{" "}
                      <a
                        href={
                          (doordashData && doordashData.data?.tracking_url) ||
                          o.doordashTrackingUrl
                        }
                        target="_blank"
                      >
                        HERE
                      </a>
                    </h2>
                  </div>
                ) : (
                  <div className="order-options">
                    {orderStatus === "confirmed" ? (
                      <button onClick={handleReadyForPickUp} className="accept">
                        Ready for pick up?
                      </button>
                    ) : orderStatus === "canceled" ? null : (
                      <button onClick={handleAcceptOrder} className="accept">
                        Accept
                      </button>
                    )}
                    {orderRejected ? (
                      <React.Fragment>
                        <div className="reject-options">
                          <label htmlFor="reject-reason">
                            Select a reason:
                          </label>
                          <select
                            id="reject-reason"
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)}
                          >
                            <option value="">-- Select a reason --</option>
                            <option value="Item(s) out of stock">
                              Item(s) out of stock
                            </option>
                            <option value="Closed for the day">
                              Closed for the day
                            </option>
                            <option value="Bad customer history">
                              Bad customer history
                            </option>
                          </select>
                        </div>
                        <button onClick={handleRejectSubmit} className="reject">
                          Submit
                        </button>
                      </React.Fragment>
                    ) : orderStatus === "canceled" ? null : (
                      <button onClick={handleRejectOrder} className="reject">
                        Reject
                      </button>
                    )}
                  </div>
                )}
                {orderStatus === "canceled" && (
                  <div className="canceled-message">
                    <h2>This order has been canceled </h2>
                    <p>
                      Reason:{" "}
                      {orderCanceledReason ? (
                        <span>{orderCanceledReason}</span>
                      ) : (
                        <span>{o.rejectedReason}</span>
                      )}
                    </p>
                  </div>
                )}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default OrderModal;
