import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VerifiedNavigation from "../navigation/VerifiedNavigation";

const ProductModal = () => {
  const { productModal } = useParams();
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    stockStatus: "available",
    category: "",
    images: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    if (formData.images) {
      formData.images.forEach((image) =>
        formDataToSend.append("images", image)
      );
    }

    formDataToSend.append("productName", formData.name);
    formDataToSend.append("productPrice", formData.price);
    formDataToSend.append("productDescription", formData.description);
    formDataToSend.append("productStockStatus", formData.stockStatus);
    formDataToSend.append("productCategory", formData.category);

    try {
      const token = localStorage.getItem("authToken");
      await axios.patch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/inventory/update/product?productId=${product._id}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormData({
        name: "",
        price: "",
        stockStatus: "available",
        category: "Select One",
        images: [],
      });
      setErrorMessage(
        "Product updated successfully. Please refresh to see changes."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      window.scrollTo(0, 0);
    } catch (error) {
      setErrorMessage(
        "Image failed to upload, please try again or use a different image."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const fetchInventory = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/inventory`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      findTheProduct(response.data.userInventory);
    } catch (error) {
      console.error("There was an error retrieving your item", error);
    }
  };

  const findTheProduct = (data) => {
    const productFound = data.find(
      (product) => productModal === product.productSlug
    );
    if (productFound) {
      setProduct(productFound);
      setFormData({
        name: productFound.name,
        price: productFound.price,
        description: productFound.description,
        stockStatus: productFound.stockStatus,
        category: productFound.category,
        type: productFound.type || "",
        metadata: productFound.metadata || "",
        images: productFound.images.map((image) => image) || "",
      });
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
    fetchInventory();
  }, []);
  console.log(formData.images);
  return (
    <div className="product-modal">
      <VerifiedNavigation />
      <div className="product-modal-container">
        {product ? (
          <div className="product">
            <div className="item">
              <div className="form-container">
                <h2>Edit This Product</h2>
                <form onSubmit={handleSubmit}>
                  <div className="item-image">
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div className="item-image-container" key={index}>
                        <img
                          src={
                            formData.images[index]
                              ? typeof formData.images[index] === "string"
                                ? formData.images[index]
                                : URL.createObjectURL(formData.images[index])
                              : "placeholder-image-url"
                          }
                          className="image"
                          alt={`Product Image ${index + 1}`}
                          key={index}
                        />
                        <input
                          type="file"
                          name={`image-${index}`}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              setFormData((prevVal) => {
                                const updatedImages = [...prevVal.images];
                                updatedImages[index] = file;
                                return { ...prevVal, images: updatedImages };
                              });
                            }
                          }}
                          accept="image/*"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Product Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter product name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Price ($)</label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      value={formData.price}
                      onChange={handleChange}
                      placeholder="Enter price"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      name="description"
                      id="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Product description"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="stockStatus">Stock Availability</label>
                    <select
                      name="stockStatus"
                      id="stockStatus"
                      value={formData.stockStatus}
                      onChange={handleChange}
                      required
                    >
                      <option value="available">Available</option>
                      <option value="unavailable">Unavailable</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="category">Category</label>
                    <select
                      onChange={handleChange}
                      name="category"
                      defaultValue={formData.category}
                    >
                      <option value="Most Popular">Most Popular</option>
                      <option value="Newest Arrivals">Newest Arrivals</option>
                      <option value="Classics">Classics</option>
                      <option value="On Sale / Discounts">
                        On Sale / Discounts
                      </option>
                      <option value="Limited Time">Limited Time</option>
                    </select>
                  </div>
                  <button type="submit" className="submit-btn">
                    Submit Product
                  </button>
                </form>
                {errorMessage ? errorMessage : null}
              </div>
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
};

export default ProductModal;
