import React, { useEffect } from "react";
import "./verifiedNavigation.css";
import { FaStore } from "react-icons/fa";
import { IoReceiptSharp } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import { IoIosSettings } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { RiLogoutBoxFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const VerifiedNavigation = ({ setOrders }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleLogout = () => {
    localStorage.clear();
    setOrders("");
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, []);

  return (
    <header className="verified-navigation">
      <nav className="verified-nav-bar">
        <ul className="verified-nav-links">
          <Link to="/manager/orders">
            <li>
              {isMobile ? null : "Orders"}
              <IoReceiptSharp />
            </li>
          </Link>
          <Link to="/manager/inventory">
            <li>
              {isMobile ? null : "Inventory"}
              <FaStore />
            </li>
          </Link>
          {/* {!isMobile && <Link to='/manager/sales'><li>Sales<VscGraph/></li></Link>} */}
          <Link to="/manager/settings">
            <li>
              {isMobile ? null : "Settings"}
              <IoIosSettings size={20} />
            </li>
          </Link>
          <div className="verified-sub-nav">
            <Link to="/manager/support">
              <li>
                <BiSupport size={20} />
              </li>
            </Link>
            <Link to="/" onClick={handleLogout}>
              <li>
                <RiLogoutBoxFill size={25} />
              </li>
            </Link>
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default VerifiedNavigation;
