import React, { useEffect, useState } from "react";
import "./inventory.css";
import VerifiedNavigation from "../navigation/VerifiedNavigation";
import { Link } from "react-router-dom";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const fetchInventory = async () => {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/inventory`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setInventory(response.data.userInventory);
  };
  useEffect(() => {
    fetchInventory();
  }, []);
  return (
    <div className="inventory">
      <VerifiedNavigation />
      <div className="inventory-container">
        <div className="inventory-header">
          <h1>Products</h1>
          <Link to="/manager/inventory/add-new-product">Add New Product</Link>
        </div>
        <div className="product-headers">
          <h2>Image</h2>
          <h2>Name</h2>
          <h2>Available</h2>
          {!isMobile && (
            <React.Fragment>
              <h2>Description</h2>
              <h2>Price</h2>
            </React.Fragment>
          )}
        </div>
        <div className="item">
          {inventory && inventory.length > 0 ? (
            inventory.map((item, index) => (
              <Link
                to={`/manager/inventory/${item.productSlug}`}
                key={index}
                className="inventory-products"
              >
                <img key={index} src={item.images[0]} alt={item.metadata} />
                <p>{item.name}</p>
                <p
                  className={
                    item.stockStatus !== "unavailable" ? "active" : "inActive"
                  }
                >
                  {item.stockStatus}
                </p>
                {!isMobile && (
                  <React.Fragment>
                    <p>{item.description}</p>
                    <p>${item.price}</p>
                  </React.Fragment>
                )}
              </Link>
            ))
          ) : (
            <p className="no-products">Products you add will show up here</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inventory;
