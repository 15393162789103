import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import VerifiedNavigation from "../navigation/VerifiedNavigation";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import "./orders.css";
import io from "socket.io-client";
const socket = io.connect(process.env.RENDER_REDIS_URL);

const Orders = ({ orders, setOrders }) => {
  const navigate = useNavigate();
  const [eventName, setEventName] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const createBusiness = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/doordash/create-business`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "There was an error creating this buisness for doordash",
        error
      );
    }
  };

  const createStore = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/doordash/create-store`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "There was an error creating this buisness for doordash",
        error
      );
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    } else {
      createBusiness();
      createStore();
    }
  }, []);

  useEffect(() => {
    try {
      socket.on("deliveryUpdateWebHook", async (data) => {
        const {
          cancellation_reason,
          event_name,
          external_delivery_id,
          support_reference,
        } = data;
        console.log(event_name)
        setEventName(event_name);
      });
    } catch (error) {
      console.error("There was an error updating the delivery status", error);
    }
  }, []);

  return (
    <div className="orders">
      <VerifiedNavigation setOrders={setOrders} />
      <div className="orders-container">
        <div className="orders-header">
          <h2>Date</h2>
          <h2>Status</h2>
          {isMobile ? null : <h2>Total</h2>}
          <h2>Action</h2>
          {isMobile ? null : <h2>Order Number</h2>}
        </div>
        {orders && orders.length > 0 ? (
          <div className="order">
            {orders.map((order, index) => (
              <div key={index} className="order-row">
                <p>{order.orderAt}</p>
                {eventName ? <p>{eventName}</p> : <p>{order.orderStatus}</p>}
                {isMobile ? null : <p>${order.totalAmount}</p>}
                <Link to={`/manager/orders/${order.orderNumber}`}>
                  <button>View</button>
                </Link>
                {isMobile ? null : <p>{order.orderNumber}</p>}
              </div>
            ))}
          </div>
        ) : (
          <p className="no-orders">
            Orders will show up here when you receive them
          </p>
        )}
      </div>
    </div>
  );
};

export default Orders;
