import React from "react";
import Login from "../../login/Login";

const CreateAccountForm = ({
  setCurrentStep,
  loginForm,
  handleChange,
  handleSubmit,
  errorMessage,
  handleAlreadyPartner,
  loading,
}) => {
  return (
    <>
      {loginForm ? (
        <Login
          setCurrentStep={setCurrentStep}
          handleAlreadyPartner={handleAlreadyPartner}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <h1>
            <span className="name">B-Street</span> Partner
          </h1>
          <div className="login-credentials">
            <label htmlFor="businessName">Business Name</label>
            <input
              placeholder="How your business will appear on the app..."
              onChange={handleChange}
              required
              autoComplete="name"
              type="text"
              name="businessName"
              id="businessName"
            />
          </div>
          <div className="login-credentials">
            <label htmlFor="businessEmail">Business Email</label>
            <input
              placeholder="contact email for customers..."
              onChange={handleChange}
              required
              autoComplete="username"
              type="email"
              name="businessEmail"
              id="businessEmail"
            />
          </div>
          <div className="login-credentials">
            <label htmlFor="password">Password</label>
            <input
              onChange={handleChange}
              required
              autoComplete="current-password"
              type="password"
              name="password"
              id="password"
            />
          </div>
          <div className="login-credentials">
            <label htmlFor="verifyPassword">Verify Password</label>
            <input
              onChange={handleChange}
              required
              autoComplete="current-password"
              type="password"
              name="verifyPassword"
              id="verifyPassword"
            />
          </div>
          <div className="login-credentials">
            <label htmlFor="businessPhone">Phone Number</label>
            <input
              placeholder="Phone number for customers to reach you..."
              onChange={handleChange}
              required
              type="tel"
              name="businessPhone"
              id="businessPhone"
            />
          </div>
          {errorMessage && (
            <div className="error-message">
              <p>{errorMessage}</p>
            </div>
          )}
          <p className="already-a-partner" onClick={handleAlreadyPartner}>
            Already a partner?
          </p>
          {loading ? <button>Loading...</button> : <button>Sign Up</button>}
        </form>
      )}
    </>
  );
};

export default CreateAccountForm;
