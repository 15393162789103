import React, { useEffect, useState } from "react";
import "./navigation.css";
import { useNavigate } from "react-router-dom";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

const Navigation = () => {
  const [renderNavLinks, setRenderNavLinks] = useState(false);
  const navigate = useNavigate();

  const cld = new Cloudinary({
    cloud: {
      cloudName: "dtlf7hj7b",
    },
  });

  const logo = cld.image("B-Street_nrfusl.png");

  const handleLogout = () => {
    localStorage.clear();
    setRenderNavLinks(true);
    navigate('/')
  };

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      setRenderNavLinks(true);
    } else {
      setRenderNavLinks(false);
    }
  }, []);

  return (
    <header>
      <nav className="nav-bar">
        <div className="nav-logo">
          <AdvancedImage className="logo" cldImg={logo} />
        </div>
        {renderNavLinks ? (
          <div className="nav-links">
            <button onClick={handleLogout} className="cta-button">
              Logout
            </button>
          </div>
        ) : null}
      </nav>
    </header>
  );
};

export default Navigation;
