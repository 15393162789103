import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CreateAccountForm from './CreateAccountForm';
import ProgressStepTwo from './ProgressStepTwo';
import { useMediaQuery } from 'react-responsive';

const LandingPage = () => {
    const [formData, setFormData] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [loginForm, setShowLoginForm] = useState();
    const [currentStep, setCurrentStep] = useState('step-one');
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [emailSupportForm, setEmailSupportForm] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleEmailFormChange = (e) => {
        const {name, value} = e.target;
        setEmailSupportForm({
            ...emailSupportForm,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
        if (!passwordRegex.test(formData.password)) {
            setErrorMessage('Password must be at least 8 characters long, include one special character, and one capital letter.');
            return;
        }
        if (formData.password !== formData.verifyPassword) {
            setErrorMessage('Passwords do not match');
            setTimeout(() => {
                setErrorMessage(null);
            }, 3000);
        } else {
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/auth/create-account`, formData, {
                    headers: {
                        "Content-Type": 'application/json'
                    } 
                });
                const { token } = response.data;
                localStorage.setItem('authToken', token);
                setCurrentStep("step-two");
            } catch (error) {
                setErrorMessage(error.response.data.message);
                console.error('There was an error while creating your account:', error);
            }
        }
    };

    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('authToken');
        try {
            await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/support`, {emailSupportForm} , {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            setEmailSupportForm('');
        } catch (error) {
            console.error("There was an error with sending this email. Please try again", error);
        }
    };
    const handleAlreadyPartner = () => {
        setShowLoginForm(!loginForm)
    };
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
  return (
    <div className="landing-page">
      <section className="hero sign-up-form">
        <div className="hero-content">
          <h2>Grow Your Business with B-Street Partners</h2>
          <p>
            Tap into same-day delivery, reach new customers, and boost your
            revenue effortlessly.
          </p>
          {isMobile ? null : (
            <button className="hero-button">Join B-Street Today</button>
          )}
        </div>
        {currentStep === "step-one" && (
          <CreateAccountForm
            loading={loading}
            setCurrentStep={setCurrentStep}
            loginForm={loginForm}
            errorMessage={errorMessage}
            handleAlreadyPartner={handleAlreadyPartner}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        )}
        {currentStep === "step-two" && <ProgressStepTwo />}
      </section>
      <section className="benefits" id="benefits">
        <h2>Why Partner with B-Street?</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <img
              src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1730397068/2203124_tzlmyz.png"
              alt="Same-day delivery"
              className="benefit-image"
            />
            <h3>Same-Day Delivery</h3>
            <p>Fast delivery options to keep your customers happy and loyal.</p>
          </div>
          <div className="benefit">
            <img
              src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1730397167/3201521_qkrrrx.png"
              alt="New market"
              className="benefit-image"
            />
            <h3>Access New Markets</h3>
            <p>
              Expand your reach and attract new customers in your local area.
            </p>
          </div>
          <div className="benefit">
            <img
              src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1730397213/15586840_mfcsdz.png"
              alt="Revenue growth"
              className="benefit-image"
            />
            <h3>Boost Your Revenue</h3>
            <p>
              Increase your income by reaching customers eager for convenience.
            </p>
          </div>
        </div>
      </section>
      <section className="contact-section">
        <h2>Get in Touch with B-Street Partners</h2>
        <p>
          Have questions about joining B-Street or want to learn more about our
          benefits? Reach out, and we’ll get back to you shortly!
        </p>
        <div className="contact-details">
          <div className="contact-info">
            <h3>Contact Information</h3>
            <p>
              Email:{" "}
              <a href="mailto:contact@shopbstreet.com">
                contact@bshopstreet.com
              </a>
            </p>
            <p>
              Phone: <a href="tel:+16463522512">+1 (646) 352-2512</a>
            </p>
          </div>
          <div className="contact-form" id="contact">
            <h3>Send Us a Message</h3>
            <form onSubmit={handleSubmitEmail}>
              <input
                onChange={handleEmailFormChange}
                type="text"
                placeholder="Your Name"
                name="text"
                required
              />
              <input
                onChange={handleEmailFormChange}
                type="email"
                placeholder="Your Email"
                name="email"
                required
              />
              <textarea
                onChange={handleEmailFormChange}
                placeholder="Your Message"
                name="message"
                required
              ></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
        <div className="social-links">
          <h3>Connect with Us</h3>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;