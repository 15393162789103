import { useNavigate } from "react-router-dom";
import Navigation from "../../navigation/Navigation";
import '../createAccount.css'
import { useEffect } from "react";import axios from "axios";
;

const ProgressStepDocuments = () => {
    const navigate = useNavigate();

    const fetchVerificaionStatus = async() => {
        const token = localStorage.getItem('authToken');
        await axios.post(`${process.env.REACT_APP_SERVER_ENDPOINT}/profile/update/verification-status`,{}, {
            headers: {"Authorization": `Bearer ${token}`}
        });
    };

    useEffect(()=>{
        if(!localStorage.getItem('authToken')){
            navigate('/');
        };
        fetchVerificaionStatus();
    },[]);
    return (
        <div className='progress-step-documents'>
            <Navigation />
            <div className="progress-step-documents-container">
                <h1>Document Submission Process for Verification</h1>
                <div className="submission-steps">
                   <h2>Your account has been submitted for review</h2>
                   <h2>Please allow 24-72 hours (usually sooner) for approval.</h2>
                   <h2>If you have ANY questions at all please contact us. We will get back to you within a few hours.</h2> 
                   <a href="mailto:contact@shopbstreet.com" target="_blank"rel="noopener noreferrer"> contact@shopbstreet.com</a> 
                </div>
            </div>
        </div>
    );
};

export default ProgressStepDocuments;
