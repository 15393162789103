import React, { useEffect, useState } from "react";
import VerifiedNavigation from "../navigation/VerifiedNavigation";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddProducts = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    stockStatus: "available",
    category: "",
    images: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const createSlug = (productName) => {
    return productName
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    const formDataToSend = new FormData();

    formDataToSend.append("name", formData.name);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("stockStatus", formData.stockStatus);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("productSlug", createSlug(formData.name));

    Array.from(formData.images).forEach((image) => {
      formDataToSend.append("images", image);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/inventory/add-new-product`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormData({
        name: "",
        price: "",
        description: "",
        stockStatus: "",
        category: "",
        images: [],
        itemType: "",
        mostPopular: false,
      });
    } catch (error) {
      console.error("Error uploading product:", error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, []);

  return (
    <div className="add-product">
      <VerifiedNavigation />
      <div className="form-container">
        <h2>Upload Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Product Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter product name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="price">Price ($):</label>
            <input
              type="number"
              name="price"
              id="price"
              value={formData.price}
              onChange={handleChange}
              placeholder="Enter price"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Product description"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="stockStatus">Stock Availability:</label>
            <select
              name="stockStatus"
              id="stockStatus"
              value={formData.stockStatus}
              onChange={handleChange}
              required
            >
              <option value="available">Available</option>
              <option value="unavailable">Unavailable</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="category">Category List</label>

            <select onChange={handleChange} name="category" defaultValue="">
              <option value="" disabled>
                Select One
              </option>
              <option value="Most Popular">Most Popular</option>
              <option value="Newest Arrivals">Newest Arrivals</option>
              <option value="Classics">Classics</option>
              <option value="On Sale / Discounts">On Sale / Discounts</option>
              <option value="Limited Time">Limited Time</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="images">Product Images: (Selecet up to 4)</label>
            {formData.images.map((image, index) => (
              <img
                src={URL.createObjectURL(image)}
                alt=""
                key={index}
                style={{ width: "100px", marginRight: "10px" }}
              />
            ))}
            <input
              type="file"
              name="images"
              id="images"
              onChange={(e) => {
                const files = Array.from(e.target.files);
                console.log(files)
                setFormData((prevData) => ({
                  ...prevData,
                  images: [...prevData.images, ...files],
                }));
              }}
              multiple
              required
            />
          </div>
          <button type="submit" className="submit-btn">
            Submit Product
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddProducts;
