import axios from "axios";
import React, { useState } from "react";
import Navigation from "../../navigation/Navigation";
import useStripeConnect from "../../hooks/useStripeConnect";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useNavigate } from "react-router-dom";

const StripeSetup = () => {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  const navigate = useNavigate();

  const handleExit = async () => {
    setOnboardingExited(true);
    const token = localStorage.getItem("authToken");
    const applicationNumber = Math.random();
    await axios.post(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/profile/add/application-number`,
      { applicationNumber },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/register/account-setup");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tier = e.target.name;
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/stripe/account`,
        { tier },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setAccountCreatePending(false);
      const { account, error } = response.data;

      if (account) {
        setConnectedAccountId(account);
      }

      if (error) {
        setError(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error choosing your tier", error);
      
    }
  };
  return (
    <React.Fragment>
      <Navigation />
      {connectedAccountId && !stripeConnectInstance && (
        <h2>
          Completing this onboarding process will allow you to receive your
          payouts.
        </h2>
      )}
      {!accountCreatePending && !connectedAccountId && (
        <React.Fragment>
          <div className="partner-tiers">
            <h2>Choose Your Partner Package</h2>
            <div className="tier-container">
              <div className="tier basic-tier">
                <h3>Basic Package</h3>
                <ul>
                  <li>Same-Day Delivery Access</li>
                  <li>Priority Listing</li>
                  <li>Performance metrics</li>
                  <li>Enhanced Customer Insights</li>
                  <li>Exclusive Access to Promotions</li>
                  <li>Priority Customer Service</li>
                  <li>Weekly Payouts</li>
                  <li>15% commission per transaction</li>
                </ul>
                <button
                  onClick={(e) => handleSubmit(e)}
                  name="basic"
                  className="choose-button"
                >
                  {loading ? "Loading" : "Choose Basic"}
                </button>
              </div>

              {/* <div className="tier premium-tier">
              <h3>Premium Package</h3>
              <ul>
                  <li>Same-Day Delivery Access</li>
                  <li>Priority Listing</li>
                  <li>Performance metrics</li>
                  <li>Enhanced Customer Insights</li>
                  <li>Exclusive Access to Promotions</li>
                  <li>Priority Customer Service</li>
                  <li>Weekly Payouts</li>
                  <li>20% commission per transaction</li>
              </ul>
              <strong>29.99 / Month - deducted from your payout</strong>
              <button onClick={ (e)=>handleSubmit(e) } name='premium' className="choose-button">Choose Premium</button>
              </div> */}
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="stripe-container">
        {stripeConnectInstance && (
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding onExit={handleExit} />
          </ConnectComponentsProvider>
        )}
        {error && <p className="error">Something went wrong!</p>}
        {(connectedAccountId || accountCreatePending || onboardingExited) && (
          <div className="dev-callout">
            {connectedAccountId && (
              <p>
                Your connected account ID is:{" "}
                <code className="bold">{connectedAccountId}</code>
              </p>
            )}
            {accountCreatePending && <p>Creating a connected account...</p>}
            {onboardingExited && (
              <p>The Account Onboarding component has exited</p>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StripeSetup;
