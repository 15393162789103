import React, { useEffect, useState } from 'react'
import Navigation from '../../navigation/Navigation'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CiCircleCheck } from "react-icons/ci";

const StoreSetup = () => {
    const navigate = useNavigate();
    const [applicationNumber, setApplicationNumber] = useState('');
    const [storeDetails, setStoreDetails] = useState('');

    const handleStripeSetup = () => {
        navigate('/register/account-setup/package');
    };

    const fetchSetupStatus = async() => {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(`${process.env.REACT_APP_SERVER_ENDPOINT}/profile`,{
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
        setApplicationNumber(response.data.user.applicationNumber);
        setStoreDetails(response.data.user.storeOperations);
    };

    const handleStoreSetup = () => {
        navigate('/register/account-setup/store');
    };

    const handleNext = async () => {
        navigate("/register/account-setup/await-review")
    };
    

    useEffect(()=>{
        fetchSetupStatus();
    },[]);

  return (
    <div className="store-setup">
        <Navigation />
        <section className="store-setup-container">
            <h1>Complete the Account Setup Process</h1>

            <div className="progress-indicator">
                <div className="step completed">1</div>
                <div className="line completed"></div>
                <div className={storeDetails && applicationNumber ? ' step completed' : "step"}>2</div>
            </div>

            <section className="store-setup-steps">
                <section className="store-details">
                    <div className="details-header">
                        <h2>1. Set Up Your Store</h2>
                    </div>
                    <p>Provide essential information about your store, including opening hours, and contact details.</p>
                    {
                        storeDetails ? 
                        <CiCircleCheck size={40} color='green'/> 
                        :
                        <button onClick={handleStoreSetup} className="setup-button">Start Store Setup</button>
                    }
                </section>
                
                <section className="stripe-setup">
                    <div className="details-header">
                        <h2>2. Set Up Stripe to Receive Payouts</h2>
                    </div>
                    <p>Link your bank account to Stripe to enable secure and timely payouts for your transactions.</p>
                    {
                        applicationNumber ? 
                        <CiCircleCheck size={40} color='green'/> 
                        :
                        <button onClick={handleStripeSetup} className="setup-button">Set Up Stripe</button>
                    }
                </section>
            </section>
            <div className="store-setup-next-container">
                <button onClick={handleNext} disabled={!storeDetails || !applicationNumber} className={'store-setup-next'}>Next</button>
            </div>
        </section>
    </div>

  )
}

export default StoreSetup